import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import { ThemeProvider } from "styled-components";
import { useDarkMode } from "./components/useDarkMode";
import { GlobalStyles } from "./components/GlobalStyle";
import { lightTheme, darkTheme } from "./components/Themes";
import Toggle from "./components/Toggler";

function App() {
  const [theme, themeToggler] = useDarkMode();

  const themeMode = theme === "light" ? lightTheme : darkTheme;
  return (
    <ThemeProvider theme={themeMode}>
      <>
        <GlobalStyles />
        <div className="d-flex flex-row-reverse">
          <div className="p-3">
            <Toggle theme={theme} toggleTheme={themeToggler} />
          </div>
        </div>
        <Container className="mt-1 mt-lg-5">
          <Row>
            <Col lg={{ span: 4, offset: 4 }} md={{ span: 6, offset: 3 }}>
              <div className="d-flex flex-column mb-5">
                <div className="mb-3 mt-3 avatar"></div>
                <h1 className="h5 mb-3 text-center ">Liza M. Ortiz</h1>
                {/* <div className="mb-3 text-center">
                  Entrepreneur | Meetings & Events Planner<br></br>Living on my
                  terms | Regret nothing | Faith | Believe | Dream | aka Lo
                </div> */}
                <a href="https://ortizandco.com/">
                  <div className="box mb-3">
                    <div className="links box-links">
                      <div className="d-flex">
                        <div className="icon-img link-media-3">
                          {/* <img src={item.icon} alt="Link Icons" /> */}
                        </div>
                        <div className="p-2 ml-2 mt-1">
                          Visit us at <strong>ortizandco.com</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="https://fliphtml5.com/bookcase/fjpzc">
                  <div className="box mb-3">
                    <div className="links box-links">
                      <div className="d-flex">
                        <div className="icon-img link-media-6">
                          {/* <img src={item.icon} alt="Link Icons" /> */}
                        </div>
                        <div className="p-2 ml-2 mt-1">
                          Click to view the Ortiz & Co., <br></br> scope of
                          services flipbooks
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="https://shop.kreativetheory.com/">
                  <div className="box mb-3">
                    <div className="links box-links">
                      <div className="d-flex">
                        <div className="icon-img link-media-4">
                          {/* <img src={item.icon} alt="Link Icons" /> */}
                        </div>
                        <div className="p-2 ml-2 mt-1">
                          Access our promotional <br></br>branded items
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="https://www.linkedin.com/in/lizamortiz/">
                  <div className="box mb-3">
                    <div className="links box-links">
                      <div className="d-flex">
                        <div className="icon-img link-media-2">
                          {/* <img src={item.icon} alt="Link Icons" /> */}
                        </div>
                        <div className="p-2 ml-2 mt-1">
                          Connect with me on LinkedIn
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="https://www.instagram.com/lizamortiz/">
                  <div className="box mb-3">
                    <div className="links box-links">
                      <div className="d-flex">
                        <div className="icon-img link-media-5">
                          {/* <img src={item.icon} alt="Link Icons" /> */}
                        </div>
                        <div className="p-2 ml-2 mt-1">
                          Follow my personal blog <br></br>on Instagram
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    </ThemeProvider>
  );
}

export default App;
